import { InitialValues } from 'forms/types';
import { FormRenderProps } from 'react-final-form';
import { LifeApplicationInfo } from 'context/FormContext/types';

export type Props = FormRenderProps<LifeApplicationInfo, InitialValues<LifeApplicationInfo>> & {
  moreProducts: boolean;
};

export const PRODUCT_OFFERINGS_TYPES = [
  { label: 'Term Life', value: 'term' },
  { label: 'Single-Premium Whole Life (SPWL)', value: 'spwl' },
  { label: 'Guaranteed Universal Life (GUL)', value: 'gul' },
  { label: 'Whole Life', value: 'wholeLife' },
  { label: 'Indexed Universal Life (IUL) ', value: 'iul' },
];

export const DEFAULT_OFFERING = { label: 'Term Life', value: 'term' };

export const MOTIVATIONS = [
  {
    label: 'Protect My Family / Replace My Income',
    value: 'Protect My Family / Replace My Income',
  },
  { label: 'Leave a Legacy / Estate Conservation', value: 'Leave a Legacy / Estate Conservation' },
  { label: 'Long-Term Financial Growth', value: 'Long-Term Financial Growth' },
];

export const PAYMENT_MODES = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Annually', value: 'annually' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Semi-Annually', value: 'semi_annually' },
];

export const SPWL_PAYMENT_MODE = [{ label: 'Single Payment', value: 'single_payment' }];

export const GUARANTEED_AGE = [
  { label: '90', value: 90 },
  { label: '95', value: 95 },
  { label: '100', value: 100 },
  { label: '105', value: 105 },
  { label: '110', value: 110 },
  { label: '115', value: 115 },
  { label: '121', value: 121 },
];

export const PAY_AGE = [
  { label: 'Single Pay', value: 'Single Pay' },
  { label: 'Short Pay', value: 'Short Pay' },
  { label: 'Survivorship', value: 'Survivorship' },
  { label: 'Pay to 65', value: 'Pay to 65' },
  { label: 'Pay to 100', value: 'Pay to 100' },
];
